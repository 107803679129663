<template>
  <div class="datadownload">
       <div class="content" v-if="getdata.length > 0">
         <div class="contents" v-for="(item,index) in getdata" :key="index">
           <p class="imgs" v-if="item.class == 'jpg' || item.class == 'peg' || item.class == 'png' || item.class == 'gif' || item.class == 'bmp' || item.class == 'ebp'">
              <viewer @inited="inited" :ref="index" width="0.75rem">
                <img :src="item.url" alt="">
              </viewer>
             </p>
           <p class="imgs" id="iframe" v-else><img src="../../assets/image/d455504e35e2119f9c1753a4af34db8.png" alt=""></p>
           <p class="tite">{{item.name}}</p>
           <p class="upload">
             <span @click.stop="uploaddata(item)">
               {{$t('language.Downloadthedata')}}
             </span>
             <!-- 下载资料 -->
             
             <span  v-if="item.class == 'jpg' || item.class == 'peg' || item.class == 'png' || item.class == 'gif' || item.class == 'bmp' || item.class == 'ebp'" @click="preview(item)">
               {{$t('language.preview')}}
             </span>
             <span  v-else @click="previewPdf(item)">
               {{$t('language.preview')}}
             </span>
             <!-- 预览 -->
           </p>
           <p class="shade"></p>
         </div>
       </div>

       <div v-if="getdata.length == 0" style="width:100%;display: flex;justify-content: center;">
                    <img style="width:400px;height:300px" src="../../assets/image/queshengye.png" alt="" />
                  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src:""
    }
  },
  props:["getdata"],
  methods: {
    inited(viewer) {
				this.$viewer = viewer
		},
    preview(){
      this.$viewer.show()
    },
    previewPdf(val){
      window.open(val.url)
    },
    uploaddata(val){
      let data = {
        name:val.name,
        url:window.location.origin+val.url
      }
      this.$api.uploaddata(data,res => {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", val.name); //指定下载后的文件名，防跳转
          document.body.appendChild(link);
          link.click();
        })
    },
  },
}
</script>

<style scoped> 
  .datadownload{
    width: 100%;
    height: auto;
    background: #fafafa;
    padding: 60px 0 190px;
    box-sizing: border-box;
  }
  .content{
    width: 1400px;
    height: auto;
    margin: 0 auto;
  }
  .contents{
    width: 445px;
    height: 275px;
    background: #ffffff;
    float: left;
    margin-right: 25px;
    margin-bottom: 30px;
    position: relative;
  }
  .shade{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;top: 0;
    background: rgba(0, 0, 0, .5);
    display: none;
  }
  .contents:nth-of-type(3n){
    margin-right: 0;
  }
  .tite{
    width: 100%;
    height: 65px;
    background: #bfd4e3;
    text-align: center;
    line-height: 65px;
  }
  .imgs{
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgs img{
    width: 100px;
    height: 100px;
  }
  .contents:hover .upload{
    display: block;
  }
  .contents:hover .shade{
    display: block;
    z-index: 3;
  }
  .contents:hover .tite{
    background: #1f6494;
    color: #fff;
  }
  .contents .upload{
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;right: 0;
    top: 160px;
    margin: auto;
    z-index: 4;
    display: none;
    padding: 0 40px;
    box-sizing: border-box;
  }
  .contents .upload span:nth-of-type(1){
    width: 150px;
    height: 40px;
    background: #fff;
    text-align: center;
    line-height: 40px;
    border-radius: 25px;
    cursor: pointer;
    display: block;
    float: left;
  }
  .contents .upload span:nth-of-type(2){
    width: 150px;
    height: 40px;
    background: #fff;
    text-align: center;
    line-height: 40px;
    border-radius: 25px;
    cursor: pointer;
    display: block;
    float: right;
  }
</style>