<template>
  <div class="datadownload">
    <div class="nav">
      <Carousel show="1" />
    </div>
    <div class="navs">
      <div class="navcon">
        <p class="nav_p">
          <!-- 展馆平面图 -->
          <span
            :style="{
              background: show == 'HallPlan' ? '#197CC3' : '#fff',
              color: show == 'HallPlan' ? '#fff' : '#000',
              zIndex: show == 'HallPlan' ? '5' : '',
            }"
            @click="gopage('HallPlan', $event, '1')"
            >{{ $t("language.HallPlan") }} <b>|</b>
          </span>
          <!-- 场地动线图 -->
          <span
            :style="{
              background: show == 'SiteDynamicLineMap' ? '#197CC3' : '#fff',
              color: show == 'SiteDynamicLineMap' ? '#fff' : '#000',
              zIndex: show == 'SiteDynamicLineMap' ? '5' : '',
            }"
            @click="gopage('SiteDynamicLineMap', $event, '2')"
            >{{ $t("language.SiteDynamicLineMap") }} <b>|</b></span
          >
          <!-- 宣传手册 -->
          <span
            :style="{
              background: show == 'Brochure' ? '#197CC3' : '#fff',
              color: show == 'Brochure' ? '#fff' : '#000',
              zIndex: show == 'Brochure' ? '5' : '',
            }"
            @click="gopage('Brochure', $event, '3')"
            >{{ $t("language.Brochure") }} <b>|</b></span
          >
          <!-- 管理规定 -->
          <span
            :style="{
              background: show == 'ManagementRegulations' ? '#197CC3' : '#fff',
              color: show == 'ManagementRegulations' ? '#fff' : '#000',
              zIndex: show == 'ManagementRegulations' ? '5' : '',
            }"
            @click="gopage('ManagementRegulations', $event, '4')"
            >{{ $t("language.ManagementRegulations") }}<b>|</b></span
          >
          <!-- 批报文件 -->
          <span
            :style="{
              background: show == 'ApprovalDocument' ? '#197CC3' : '#fff',
              color: show == 'ApprovalDocument' ? '#fff' : '#000',
              zIndex: show == 'ApprovalDocument' ? '5' : '',
            }"
            @click="gopage('ApprovalDocument', $event, '5')"
            >{{ $t("language.ApprovalDocument") }}</span
          >
        </p>
        <p>
          <van-icon color="#999999" name="wap-home" size="20" />
          <span
            >{{ $t("language.current") }}
            <!-- 当前位置 -->
            : {{ $t("language.HOMEpage") }}
            <!-- 首页 -->
            >
            <span v-html="$t('language.DataDownload')" class="spans"></span>
            <!-- 资料下载 -->
            > {{ $t(`language.${show}`) }}
            <!-- 报批文件 -->
          </span>
        </p>
      </div>
    </div>
    <div class="content">
      <Datadownload :getdata="getdatas" />
    </div>
    <div class="bot">
      <Bottom />
    </div>
  </div>
</template>

<script>
import Datadownload from "../../components/pcdatadownload/datadownload.vue";
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
export default {
  components: {
    Carousel,
    Bottom,
    Datadownload,
  },
  data() {
    return {
      show: "",
      getdatas: [],
    };
  },
  created() {
    this.showcomponent();
  },
  mounted() {},
  methods: {
    // 获取下载文件
    getdata(val) {
      let data = {
        pageNum: "1",
        pageSize: "99999",
        type: val,
      };
      this.$api.datadownload(data, (res) => {
        if (res.data.code == 200) {
          this.getdatas = res.data.rows.map((item) => {
            item.class = item.url.substring(
              item.url.length - 3,
              item.url.length
            );
            return item;
          });
          this.scroll();
        }
      });
    },
    scroll() {
      let traffic = document.getElementsByClassName("datadownload")[0];
      this.$nextTick(() => {
        setTimeout(() => {
          let targetbox = document.getElementsByClassName("navcon")[0];
          traffic.scrollTop = targetbox.offsetTop;
        });
      });
    },
    showcomponent() {
      if (this.$route.query.path == "展馆平面图") {
        this.show = "HallPlan";
        this.getdata("1");
      } else if (this.$route.query.path == "场地动线图") {
        this.show = "SiteDynamicLineMap";
        this.getdata("2");
      } else if (this.$route.query.path == "宣传手册") {
        this.getdata("3");
        this.show = "Brochure";
      } else if (this.$route.query.path == "管理规定") {
        this.getdata("4");
        this.show = "ManagementRegulations";
      } else if (this.$route.query.path == "报批文件") {
        this.getdata("5");
        this.show = "ApprovalDocument";
      } else if (this.$route.query.path == "/") {
        this.getdata("1");
        this.show = "HallPlan";
      } else if (this.$route.query.path == undefined) {
        this.show = "HallPlan";
        this.getdata("1");
      }
    },
    gopage(val, e, index) {
      if (val == "HallPlan") {
        this.$router.replace({
          path: "/datadownload",
          query: { path: `展馆平面图` },
        });
      } else if (val == "SiteDynamicLineMap") {
        this.$router.replace({
          path: "/datadownload",
          query: { path: `场地动线图` },
        });
      } else if (val == "Brochure") {
        this.$router.replace({
          path: "/datadownload",
          query: { path: `宣传手册` },
        });
      } else if (val == "ManagementRegulations") {
        this.$router.replace({
          path: "/datadownload",
          query: { path: `管理规定` },
        });
      } else if (val == "ApprovalDocument") {
        this.$router.replace({
          path: "/datadownload",
          query: { path: `报批文件` },
        });
      }
      this.getdata(index.toString());
      this.show = val;
      e.target.style.backgroundColor = "#197CC3";
      e.target.style.color = "#fff";
    },
  },
};
</script>

<style scoped>
.spans >>> span {
  font-size: 15px !important;
}
.datadownload {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.bot {
  width: 100%;
}
.content {
  width: 100%;
}
.datadownload::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.nav {
  width: 100%;
  height: 100%;
}
.navcon {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.navs {
  width: 1400px;
  margin: 0 auto;
}
.navcon p:nth-of-type(2) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navconright {
  line-height: 71px;
}
.navcon p {
  font-size: 15px;
  display: inline-block;
  line-height: 70px;
}
.nav_p :hover {
  background-color: #1a7cc3 !important;
  color: white !important;
  cursor: pointer;
  z-index: 5 !important;
}
.nav_p :hover b {
  display: none !important;
}
.nav_p span {
  position: relative;
}
.nav_p span b {
  position: absolute;
  z-index: 2 !important;
  right: -3px;
}
.navcon p:nth-of-type(1) {
  height: 100%;
  float: left;
}
.navcon p:nth-of-type(1) span {
  padding: 0 20px;
  float: left;
  text-align: center;
}
.navcon p:nth-of-type(1) span:nth-last-of-type(1) {
  border-right: 0;
}
</style>