var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datadownload"},[_c('div',{staticClass:"nav"},[_c('Carousel',{attrs:{"show":"1"}})],1),_c('div',{staticClass:"navs"},[_c('div',{staticClass:"navcon"},[_c('p',{staticClass:"nav_p"},[_c('span',{style:({
            background: _vm.show == 'HallPlan' ? '#197CC3' : '#fff',
            color: _vm.show == 'HallPlan' ? '#fff' : '#000',
            zIndex: _vm.show == 'HallPlan' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('HallPlan', $event, '1')}}},[_vm._v(_vm._s(_vm.$t("language.HallPlan"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'SiteDynamicLineMap' ? '#197CC3' : '#fff',
            color: _vm.show == 'SiteDynamicLineMap' ? '#fff' : '#000',
            zIndex: _vm.show == 'SiteDynamicLineMap' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('SiteDynamicLineMap', $event, '2')}}},[_vm._v(_vm._s(_vm.$t("language.SiteDynamicLineMap"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'Brochure' ? '#197CC3' : '#fff',
            color: _vm.show == 'Brochure' ? '#fff' : '#000',
            zIndex: _vm.show == 'Brochure' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('Brochure', $event, '3')}}},[_vm._v(_vm._s(_vm.$t("language.Brochure"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'ManagementRegulations' ? '#197CC3' : '#fff',
            color: _vm.show == 'ManagementRegulations' ? '#fff' : '#000',
            zIndex: _vm.show == 'ManagementRegulations' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('ManagementRegulations', $event, '4')}}},[_vm._v(_vm._s(_vm.$t("language.ManagementRegulations"))),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'ApprovalDocument' ? '#197CC3' : '#fff',
            color: _vm.show == 'ApprovalDocument' ? '#fff' : '#000',
            zIndex: _vm.show == 'ApprovalDocument' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('ApprovalDocument', $event, '5')}}},[_vm._v(_vm._s(_vm.$t("language.ApprovalDocument")))])]),_c('p',[_c('van-icon',{attrs:{"color":"#999999","name":"wap-home","size":"20"}}),_c('span',[_vm._v(_vm._s(_vm.$t("language.current"))+" "),_vm._v(" : "+_vm._s(_vm.$t("language.HOMEpage"))+" "),_vm._v(" > "),_c('span',{staticClass:"spans",domProps:{"innerHTML":_vm._s(_vm.$t('language.DataDownload'))}}),_vm._v(" > "+_vm._s(_vm.$t(("language." + _vm.show)))+" ")])],1)])]),_c('div',{staticClass:"content"},[_c('Datadownload',{attrs:{"getdata":_vm.getdatas}})],1),_c('div',{staticClass:"bot"},[_c('Bottom')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }